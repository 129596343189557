import Header from '../Header'
import AboutMe from '../AboutMe'
import Projects from '../Projects'
import HireMe from '../HireMe'
import { ContactUs } from '../ContactMe'
import Experience from '../Experience'
import { Link } from 'react-scroll'
import './index.css'

const Home = () => (
  <div className='app-container'>
    <Header />
    <div className="home-container" id="home">
      <h1 className="hero-heading">Hey, There</h1>
      <p className="hero-subtitle">Welcome to Aravind Nethi's Portfolio Showcase, where digital innovation meets precision coding.</p>
      <Link to="contactme" className='link-container' spy={true}
        smooth={true}
        hashSpy={true}
        offset={50}
        duration={500} delay={1000}
        isDynamic={true}>Contact Me
      </Link>
    </div>
    <img src="https://res.cloudinary.com/de2lwnsu7/image/upload/v1707300756/photography-ideas-creative-occupation-design-studio-concept-min_yubmzi.jpg" alt="hero" className="hero-image" />
    <AboutMe />
    <Projects />
    <HireMe />
    <Experience />
    <ContactUs />
  </div>
)

export default Home