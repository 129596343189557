import React, { useState, useEffect } from 'react';
import { FaBriefcase, FaBuilding, FaCalendarAlt, FaTools } from 'react-icons/fa';
import './index.css';

const TimelineExperience = () => {
    const [expandedCard, setExpandedCard] = useState(null);

    const experiences = [
        {
            title: 'Jr. MEAN Stack Developer',
            company: 'Geeth LLC',
            period: 'Nov 2024 - Present',
            skills: 'Angular.js, Nest.js, Typescript, Ionic Framework, MongoDB',
            description: [
                'Develop applications using the MEAN stack.',
                'Work on AI integration projects and frameworks for mobile app development.'
            ]
        },
        {
            title: 'Jr. Backend Developer',
            company: 'Edulab',
            period: 'Sep 2024 - Nov 2024',
            skills: 'Node.js, Express.js, MySQL, TypeORM, Keycloak',
            description: [
                'Developed and maintained backend services, implementing secure user authentication using Keycloak and building optimized database schemas with MySQL and TypeORM, improving performance and scalability.',
                'Automated routine data export tasks, fine-tuned backend queries to reduce average response time by 20%, and collaborated with frontend teams to ensure seamless integration and a 99.9% system uptime.'
            ]
        },
        {
            title: 'Full Stack Developer Intern',
            company: 'Aptitude Guru Hem',
            period: 'Jul 2024 - Sep 2024',
            skills: 'React.js, Node.js, Express.js, MongoDB, Jira, SonarQube, Machine Learning',
            description: [
                'Worked on an AI-powered Job Recommendation System and an Affiliate Marketing Platform, applying machine learning algorithms to match users with suitable job opportunities and fostering collaboration between marketers and partners to drive business growth.',
                'Designed efficient APIs, implemented caching strategies to optimize performance, conducted code reviews, and mentored junior developers, enhancing team productivity and application reliability.'
            ]
        },
        {
            title: 'Web Development Intern',
            company: 'Homaid',
            period: 'Feb 2024 - May 2024',
            skills: 'React.js, Node.js, Express.js, MySQL, Hostinger VPS',
            description: [
                'Assisted in the development and testing of web applications, enhancing site usability and functionality, which resulted in a 15% increase in user engagement.',
                'Developed and maintained a responsive web application for Homaid Services using React.js for the frontend, Node.js for the backend, and MySQL for the database, contributing to both the user interface and server-side logic to enhance overall functionality and user experience.'
            ]
        },
        {
            title: 'Web Development Intern',
            company: 'Yuvacracy Foundation',
            period: 'Jan 2023 - Jun 2023',
            skills: 'HTML, CSS, JavaScript',
            description: [
                'Designed and implemented a dynamic chatbot for the Yuvacracy Foundation website using HTML, CSS, and JavaScript, improving user interaction and engagement by 30%.',
                'Submitted high-fidelity prototypes demonstrating the chatbot\'s functionality and user interface, resulting in a 25% increase in user satisfaction and usability testing scores.'
            ]
        }
    ];

    const handleCardClick = (index) => {
        if (window.innerWidth <= 768) {
            setExpandedCard(expandedCard === index ? null : index);
        }
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 768) {
                setExpandedCard(null);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="timeline-container" id="experience">
            <h1 className='work-experience-heading'>Work Experience</h1>
            <div className="timeline">
                {experiences.map((exp, index) => (
                    <div
                        key={index}
                        className={`timeline-item ${expandedCard === index ? 'expanded' : ''}`}
                        onClick={() => handleCardClick(index)}
                    >
                        <div className="timeline-dot" />
                        <div className="timeline-content">
                            <div className="timeline-header">
                                <h3 className="timeline-title">
                                    <FaBriefcase style={{ marginRight: '10px', color: '#fff' }} />
                                    {exp.title}
                                </h3>
                                <h4 className="timeline-company">
                                    <FaBuilding style={{ marginRight: '10px', color: '#808080' }} />
                                    {exp.company}
                                </h4>
                                <p className="timeline-period">
                                    <FaCalendarAlt style={{ marginRight: '10px', color: '#666' }} />
                                    {exp.period}
                                </p>
                            </div>
                            <div className="timeline-skills">
                                <FaTools style={{ marginRight: '10px', color: '#fff' }} />
                                <strong>Key Skills: </strong> {exp.skills}
                            </div>
                            <div className="timeline-description">
                                {exp.description.map((desc, i) => (
                                    <p key={i}>{desc}</p>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TimelineExperience;
