import Home from './components/Home'
import { Analytics } from '@vercel/analytics/react';
import { motion, useScroll } from "framer-motion";

import './App.css'

const App=()=>{
  const { scrollYProgress } = useScroll();
  return(
  <div className='App animated fadeIn'>
     <motion.div
        className="progress-bar"
        style={{ scaleX: scrollYProgress }}
      />
    <Home/>
    <Analytics/>
  </div>
)
}
export default App