import './index.css'
import react from '../../assets/react.png'
import python from '../../assets/python.png'
import node from '../../assets/node.png'
import express from '../../assets/express.png'
import sql from '../../assets/sql.png'
import mongodb from '../../assets/mongodb.png'
import angular from '../../assets/angular.png'
import nestjs from '../../assets/nestjs.png'

const SkillSlider = () => (
    <div className='slider-container'>
        <div className='slider'>
            <div className='slide-track'>
                <div className='slide'>
                    <img src={`${react}`} alt='react' />
                </div>
                <div className='slide'>
                    <img src={`${angular}`} alt='angular' />
                </div>
                <div className='slide'>
                    <img src={`${python}`} alt='python' />
                </div>
                <div className='slide'>
                    <img src={`${node}`} alt='node' />
                </div>
                <div className='slide'>
                    <img src={`${express}`} alt='express' />
                </div>
                <div className='slide'>
                    <img src={`${nestjs}`} alt='nestjs' />
                </div>
                <div className='slide'>
                    <img src={`${sql}`} alt='sql' />
                </div>
                <div className='slide'>
                    <img src={`${mongodb}`} alt='mongodb' />
                </div>
            </div>
        </div>
    </div>
)

export default SkillSlider