import ProjectItem from '../ProjectItem'
import Blogs from "../../assets/Blogs.jpg"
import Netflix from "../../assets/Netflix.jpg"
import Nxtwatch from "../../assets/Nxtwatch.jpg"
import Todo from "../../assets/Todo.jpg"
import Whatsapp from "../../assets/Whatsapp.jpg"
import Book from "../../assets/Book.jpg"
import Investigation from "../../assets/Investigation.jpg"
import Food from "../../assets/Food.jpg"
import './index.css'

const projectsArr = [
    {
        id: 1,
        name: 'Blogs Application',
        url: 'https://blogs-app-by-arvnd.vercel.app/',
        coverImage: `${Blogs}`,
        description: 'Seamlessly browse, create, and share content. User-friendly interface, robust backend, and engaging features for immersive blogging experience.'
    },
    {
        id: 2,
        name: 'Netflix Clone',
        url: 'https://arvndmovies.ccbp.tech/',
        coverImage: `${Netflix}`,
        description: 'A feature-rich recreation offering seamless streaming, personalized recommendations, user profiles, and intuitive UI for immersive entertainment.'
    },
    {
        id: 3,
        name: 'Nxt Watch',
        url: 'https://arvinxtwatch.ccbp.tech/login',
        coverImage: `${Nxtwatch}`,
        description: 'A YouTube clone offering personalized recommendations, seamless navigation, subscriptions, and trending videos for an immersive viewing experience.'
    },
    {
        id: 4,
        name: 'To-Do Application',
        url: 'https://todo-application-by-arvnd.netlify.app/',
        coverImage: `${Todo}`,
        description: 'A dynamic todo app for streamlined task management. Seamlessly add, update, and delete tasks for enhanced productivity and organization.'
    },
    {
        id: 5,
        name: 'Whatsapp Clone',
        url: 'https://whatsapp-clone-git-master-thenethis-projects.vercel.app/login',
        coverImage: `${Whatsapp}`,
        description: 'This project used the end-to-end encryption with the OTP Authentication for the user.'
    },
    {
        id: 6,
        name: 'Book Store Application',
        url: 'https://book-application-aravind.netlify.app/',
        coverImage: `${Book}`,
        description: 'Browse, and purchase books with ease. Personalized recommendations and seamless checkout for book lovers.'
    },

    {
        id: 7,
        name: 'Investigating a Malicious Email using Maltego',
        url: 'https://drive.google.com/file/d/1OdeZHFVG2nC2gsCNQAX1GCy_slongmxa/view?usp=sharing',
        coverImage: `${Investigation}`,
        description: 'Explore "Investigating Malicious Email project leveraging Maltego data visualization, entity mapping, and link analysis for cyber threat intelligence.'
    },
    {
        id: 8,
        name: 'Food Munch',
        url: 'https://arvndfoodmunch.ccbp.tech',
        coverImage: `${Food}`,
        description: 'Simplify your dining experience. Explore diverse cuisines, book tables effortlessly, and discover exclusive deals. Your culinary adventure awaits!'
    }
]

const Projects = () => (
    <div className="project-container" id="projects">
        <h1 className='my-projects-heads'>My Projects</h1>
        <ul type="none" className="projects-container">
            {
                projectsArr.map((each) => <ProjectItem key={each.id} projectItem={each} />)
            }
        </ul>
    </div>
)

export default Projects