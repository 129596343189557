import './index.css'

const AboutMe=()=>(
    <div id="aboutme">
        <section className="about-me-container">
        <div className='about-me-inner-container'>
            <div>
                <h1 className='about-me-heading'>About Me</h1>
                <p className='about-me-description'>My name is Aravind Nethi, a web developer dedicated to crafting seamless digital experiences, adeptly blends frontend and backend skills. Proficient in HTML, CSS, and JavaScript, I design captivating user interfaces, while my expertise in Node.js and database management ensures robust backend functionality. A passionate learner at heart, I continually embrace new technologies and methodologies to stay at the forefront of the ever-evolving web development landscape. Explore my portfolio to witness the synergy of creativity, technical proficiency, and a relentless pursuit of excellence in every project.</p>
            </div>
            <img src="https://res.cloudinary.com/de2lwnsu7/image/upload/v1707308434/photo-removebg-preview_2_ehd3bg.png" alt="about-me" className='about-me-image'/>
        </div>
        </section>
    </div>
)

export default AboutMe