import './index.css'

const ProjectItem=props=>{
    const {projectItem}=props 
    const {name, url,description,coverImage}=projectItem
    return(
        <li className="project-item-container">
            <div className="project-item-content-container"> 
                <div>
                    <h1 className="project-head">{name}</h1>
                    <p className="project-description">{description}</p>
                </div>
                <a href={url} target="_blank" rel="noopener noreferrer" className="url-tag">Click here</a>
            </div>
            <img src={coverImage} alt={name} className="projects-image"/>
        </li>
    )
}

export default ProjectItem