import React, { useRef, useState } from 'react';
import { FaGithub, FaLinkedin, FaInstagram } from "react-icons/fa";
import './index.css';

export const ContactUs = () => {
  const [send, setSend] = useState(false);
  const [loading, setLoading] = useState(false); // New state for loading
  const form = useRef();

  const sendEmail = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    const formData = new FormData(form.current);
    const data = Object.fromEntries(formData);

    try {
      const response = await fetch('https://portfolio-server-q44s.onrender.com/contact-me-api', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        setSend(true);
        form.current.reset(); // Reset the form after successful submission
      } else {
        console.error('Failed to send the message.');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false); // Stop loading after request completes
    }
  };

  return (
    <div className='form-container' id="contactme">
      <p className='contact-me-heading'>Contact Me.</p>
      <h1 className='get-in-touch-heading'>GET IN TOUCH</h1>
      <form ref={form} onSubmit={sendEmail} className='form-container'>
        <label>Name</label>
        <input type="text" name="user_name" required />
        <label>Email</label>
        <input type="email" name="user_email" required />
        <label>Message</label>
        <textarea name="message" required />
        <button type="submit" className='send-btn' disabled={loading}>
          {loading ? <div className="loader"></div> : "Send"} {/* Loader inside button */}
        </button>
      </form>
      {send && !loading && <p className='msg-status'>Sent Successfully.</p>}
      <div>
        <a href="https://github.com/thenethi" target="_blank" rel="noopener noreferrer" className='social-icons'><FaGithub /></a>
        <a href="https://www.linkedin.com/in/aravindnethi/" target="_blank" rel="noopener noreferrer" className='social-icons'><FaLinkedin /></a>
        <a href="https://www.instagram.com/thenethi1603/" target="_blank" rel="noopener noreferrer" className='social-icons'><FaInstagram /></a>
      </div>
    </div>
  );
};
