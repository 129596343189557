import './index.css'
import SkillSlider from '../SkillSlider'

const HireMe = () => (
    <section className='hire-me-section' id="hireme">
        <div className='hire-me-container'>
            <h1 className='hire-me-heading'>Why Should you <mark>Hire Me</mark> ?</h1>
            <p className='hire-me-content'>
                👉 Welcome to my portfolio! I am a dynamic and skilled web developer with a passion for crafting exceptional digital experiences. With a comprehensive background in both front-end and back-end development, I bring a unique blend of creativity and technical expertise to every project.
                <br />
                👉 During my six-month internship at the Yuvacracy Foundation, I had the opportunity to delve into various aspects of web development, honing my skills across the entire stack. From designing elegant user interfaces to implementing robust server-side functionality, I gained invaluable hands-on experience that has shaped me into a well-rounded developer.
                <br />
                👉 As a full stack developer, I thrive in roles that require versatility and adaptability. Whether it's building responsive and intuitive front-end interfaces using HTML, CSS, and JavaScript, or architecting scalable and efficient back-end systems with technologies like Node.js and MySQL, I am equipped to tackle challenges at every level of the development process.
                <br />
                👉 My internship not only provided me with technical proficiency but also instilled in me a deep understanding of the importance of collaboration and communication in a team environment. I have a proven track record of working collaboratively with designers, project managers, and fellow developers to deliver high-quality solutions that exceed expectations.
                <br />
                👉 By hiring me, you can expect a dedicated and proactive developer who is committed to delivering exceptional results. With a solid foundation in full stack development and a passion for continuous learning, I am ready to take on new challenges and contribute to the success of your projects. Let's collaborate and bring your ideas to life!
            </p>
            <h3 className='skills-heading'>My Skills</h3>
            <SkillSlider />
            <a href="https://drive.google.com/file/d/110EtJPMp86YDlhw4yRlM8FQjEV1vurnT/view?usp=sharing" target="_blank" rel="noopener noreferrer" className='resume-btn'>My Resume</a>
        </div>
    </section>
)

export default HireMe