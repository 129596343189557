import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';
import { FaBars, FaTimes, FaHome, FaUser, FaProjectDiagram, FaBriefcase, FaPhone, FaHireAHelper } from 'react-icons/fa';

const Nav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background: transparent;
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  color: #fff;
  transition: background 0.3s ease-in-out;
`;

const NavLogo = styled.img`
  height: 60px;
  width: 60px;
  cursor: pointer;
`;

const DesktopNav = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  @media (max-width: 768px) {
    display: none;
  }
`;

const NavItem = styled.li`
  margin: 0 1rem;
  font-family: 'Questrial', sans-serif;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
  margin-top:5px;

  &:hover {
    color: grey;
  }
`;

const MobileNavToggle = styled.button`
  display: none;
  background: none;
  border: none;
  color: #fff;
  font-size: 1.8rem;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;

const Sidebar = styled.div`
  position: fixed;
  top: 0;
  right: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
  height: 100vh;
  width: 70%;
  max-width: 300px;
  background: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;
  transition: right 0.3s ease-in-out;
  z-index: 11;
`;

const MobileNavItem = styled.li`
  display: flex;
  align-items: center;
  margin: 1rem 0;
  font-family: 'Questrial', sans-serif;
  color: #fff;
  font-size: 1.2rem;
  cursor: pointer;
  transition: color 0.3s ease-in-out;

  & > svg {
    margin-right: 0.5rem;
  }

  &:hover {
    color: grey;
  }
`;

const Backdrop = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
`;

const Header = () => {
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      <Nav>
        <NavLogo src="https://res.cloudinary.com/de2lwnsu7/image/upload/v1707306263/Clean_Modern_Programmer_Developer_Web_Designer_Logo_ub1mxr.png" alt="Logo" />
        <DesktopNav>
          <Link to="home" smooth duration={500}><NavItem>Home</NavItem></Link>
          <Link to="aboutme" smooth duration={500}><NavItem>About Me</NavItem></Link>
          <Link to="projects" smooth duration={500}><NavItem>Projects</NavItem></Link>
          <Link to="hireme" smooth duration={500}><NavItem>Hire Me</NavItem></Link>
          <Link to="experience" smooth duration={500}><NavItem>Experience</NavItem></Link>
          <Link to="contactme" smooth duration={500}><NavItem>Contact Me</NavItem></Link>
        </DesktopNav>
        <MobileNavToggle onClick={toggleSidebar}>
          {isSidebarOpen ? <FaTimes /> : <FaBars />}
        </MobileNavToggle>
      </Nav>

      <Backdrop isOpen={isSidebarOpen} onClick={toggleSidebar} />
      <Sidebar isOpen={isSidebarOpen}>
        <Link to="home" smooth duration={500} onClick={toggleSidebar}>
          <MobileNavItem><FaHome /> Home</MobileNavItem>
        </Link>
        <Link to="aboutme" smooth duration={500} onClick={toggleSidebar}>
          <MobileNavItem><FaUser /> About Me</MobileNavItem>
        </Link>
        <Link to="projects" smooth duration={500} onClick={toggleSidebar}>
          <MobileNavItem><FaProjectDiagram /> Projects</MobileNavItem>
        </Link>
        <Link to="hireme" smooth duration={500} onClick={toggleSidebar}>
          <MobileNavItem><FaHireAHelper /> Hire Me</MobileNavItem>
        </Link>
        <Link to="experience" smooth duration={500} onClick={toggleSidebar}>
          <MobileNavItem><FaBriefcase /> Experience</MobileNavItem>
        </Link>
        <Link to="contactme" smooth duration={500} onClick={toggleSidebar}>
          <MobileNavItem><FaPhone /> Contact Me</MobileNavItem>
        </Link>
      </Sidebar>
    </>
  );
};

export default Header;
